<template>
	<div>
		<field-errors v-if="submitAttempted" :form="$v.form" field="rating"></field-errors>
		<no-ssr><star-rating v-model="stars" :show-rating="false" active-color="#3abeca" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating></no-ssr>
	</div>
</template>

<script>

import FormMixin from '@/mixins/FormMixin';
import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators';

export default {

	name: 'RateLocation',

	mixins: [ FormMixin ],

	props: {
		locationId: {
			type: Number,
			required: true,
		},
		currentRating: {
			type: Number,
			default: null
		},
	},

	data() {
		return {
			loaded: false,
			location: null,
			hovering: null,
			form: {
				rating: null,
			},
			stars: null,
		};
	},

	watch: {

		stars(numStars) {
			this.$set(this.form, 'rating', parseInt(numStars, 10) * 2);
			this.onSubmit();
		},

	},

	validations: {
		form: {
			rating: {
				required,
				numeric,
				minValue: minValue(1),
				maxValue: maxValue(10)
			},
		}
	},

	created() {
		if (!this.currentRating) {
			this.load();
		} else {
			this.form.rating = this.currentRating;
			this.stars = this.currentRating / 2;
			this.$nextTick(() => {
				this.loaded = true;
			});
		}
	},

	methods: {

		async load() {
			try {
				const { data } = await this.$api.get(`/reviews/${ this.locationId }`);

				if (data.data && data.data.rating) {
					this.form = {
						rating: data.data.rating
					};
					this.stars = data.data.rating / 2;
				}

			} catch {}

			this.loaded = true;
		},

		onSubmit() {
			this.submit(async () => {
				await this.$api.post(`reviews/${ this.locationId }`, this.form);
			}, () => {
				this.$store.dispatch('errorToast', 'Unable to save rating');
			});
		},

	},

};

</script>
