<template>
	<div>
		<div class="checkout-container page-has-nav">
			<div class="interact">
				<p>Don't forget to let {{ order.location.name }} know how they did with your order and mark your order as picked up!</p>
				<rate-location :location-id="order.location.id" :current-rating="order.rating"></rate-location>
				<div v-if="showCompleted" class="alert alert-good">
					<ul>
						<li><span class="fal fa-thumbs-up"></span>Order marked complete</li>
					</ul>
				</div>
				<div class="contain-submit" v-if="canCompleteOrder">
					<span><button @click.prevent="completeOrder" type="submit" class="submit-button">Mark Picked Up<span class="fal fa-check-circle"></span></button></span>
				</div>
			</div>

			<div class="checkout-merchant">
				<h3>Your order with {{ order.location.name }}</h3>
				<p>Your order will be ready between <date :date="order.expected_ready_at" format="h:mmA"></date> and <date :date="order.expected_ready_at_max" format="h:mmA"></date>. The shop may ask to see this receipt when you pick up your order.</p><br>
				<p><b>Pickup Address</b><br><a :href="`https://www.google.com/maps/dir/?api=1&destination=${ addressString }`" target="_blank">{{ order.location.address1 }}<br>{{ order.location.city }}, {{ order.location.state }} {{ order.location.zip }}</a></p><br>
				<p><b>Phone Number</b><br><a :href="`tel:${ order.location.phone_number }`">{{ order.location.phone_number || 'Not Available' }}</a></p><br>
				<p><a :href="`https://www.google.com/maps/dir/?api=1&destination=${ addressString }`" target="_blank">Click here</a> for <b>directions from Google</b></p>
			</div>

			<div v-for="(item, index) in order.items" :key="`item-${ index }`" class="cart-table">
				<div class="item">
					<h3>{{ item.name }}</h3>
					<ul>
						<li>{{ item.variantName }}</li>
						<li v-for="(modifierName, mIndex) in item.modifiers" :key="`item-${ index }-${ mIndex }`">{{ modifierName }}</li>
					</ul>
				</div>
				<div class="quantity">{{ item.quantity }}</div>
			</div>

			<div class="totals">
				<div class="total">Order Total: {{ order.order_total | cents }}</div>
				<div class="taxes">{{ order.taxes | cents }} Sales Tax</div>
				<div class="fees">{{ order.service_fee | cents }} Service Fee</div>
				<div class="card"><span class="fal fa-credit-card"></span> {{ order.card_brand.toLocaleLowerCase() | capitalize }} Ending In {{ order.card_last_4 }}</div>
			</div>
		</div>

		<no-ssr>
			<GmapMap v-if="order.location" :zoom="10.75" :center="mapCenter" :options="mapOptions" class="map-order-receipt">
				<GmapMarker :position="mapCenter" @click="mapInfoOpen = true" :options="{ maxWidth: 300, padding: 0 }">
					<GmapInfoWindow :position="mapCenter" :opened="mapInfoOpen" @closeclick="mapInfoOpen = null" :options="{ padding: 0 }">
						{{ order.location.name }}<br>
						{{ order.location.address1 }}<br>
						{{ order.location.city }}, {{ order.location.state }} {{ order.location.zip }}
					</GmapInfoWindow>
				</GmapMarker>
			</GmapMap>
		</no-ssr>
	</div>
</template>

<script>

import RateLocation from '../account/components/RateLocation';
import { mapGetters } from 'vuex';
import mapOptions from '@/utils/maps';

export default {

	name: 'OrderCompletedPage',

	async asyncData({ app, route, store }) {
		try {
			const { data } = await app.$api.get(`/account/orders/${route.params.orderId}`);
			return {
				order: data.order,
			};
		} catch (e) {
			store.dispatch('errorToast', 'Unable to load order');
		}
	},

	data() {
		return {
			order: {},
			mapInfoOpen: true,
			showCompleted: false,
		};
	},

	computed: {

		...mapGetters([ 'completedOrders' ]),

		addressString() {
			if (this.order.location) {
				const location = this.order.location;
				return `${ location.address1 }${ location.address2 ? ` ${location.address2}`  : '' }, ${ location.city } ${ location.state } ${ location.zip }`;
			}

			return '';
		},

		canCompleteOrder() {
			return this.completedOrders.filter(completedOrder => completedOrder.orderId === this.order.id).length > 0;
		},

		mapOptions() {
			return {
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: true,
				disableDefaultUi: false,
				styles: mapOptions.styles,
			};
		},

		mapCenter() {
			return {
				lat: parseFloat(this.order.location.latitude),
				lng: parseFloat(this.order.location.longitude),
			};
		},

	},

	methods: {

		completeOrder() {
			this.showCompleted = true;
			this.$store.dispatch('clearCompletedOrder', this.order.id);
		},

	},

	components: {
		RateLocation,
	},

};

</script>
